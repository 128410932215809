import React, { FC } from 'react'
import { Typography, WarningIcon } from '@g1/components'
import { MaintenanceMessage } from 'components/Banner/hooks/useMaintenance'

import Styles from './styles.module.scss'

type Props = {
  message: MaintenanceMessage
}

const MaintenanceBanner: FC<Props> = ({ message }) => (
  <>
    <WarningIcon />
    <Typography variant="body2Medium">{message.title}</Typography>
    <Typography variant="body2" className={Styles.message}>
      {message.body}
    </Typography>
  </>
)

export default MaintenanceBanner
