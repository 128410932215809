import { FC, PropsWithChildren, createContext, useContext } from 'react'
import {
  MaintenanceIntervals,
  useMaintenanceIntervals,
} from './useMaintenanceIntervals'

const MaintenanceContext = createContext<MaintenanceIntervals>(null)

export const MaintenanceProvider: FC<PropsWithChildren> = ({ children }) => (
  <MaintenanceContext.Provider value={useMaintenanceIntervals()}>
    {children}
  </MaintenanceContext.Provider>
)

export function useMaintenance(): MaintenanceIntervals {
  return useContext(MaintenanceContext)
}
