import React, { FC, useEffect } from 'react'
import { GalaxyOneProvider } from '@g1/components'
import Link from 'next/link'
import { IdleSignOut } from 'components/IdleSignOut'
import { SignOutModal } from 'components/SignOutModal'
import { registerMessageLoop } from 'utils/microfrontends'
import { HeaderLogo } from './components/HeaderLogo'
import { TopMenu } from './components/TopMenu'
import { PersonnelMenu } from './PersonnelMenu'

import Styles from './Styles.module.scss'

type G1HeaderProps = {
  activeItem: string
  user: string
  loggedIntoOkta: boolean
}

const G1Header: FC<G1HeaderProps> = ({ activeItem }) => {
  useEffect(registerMessageLoop, [])

  return (
    <GalaxyOneProvider theme="dark">
      <div className={Styles.mainNavMenu}>
        <Link href="/g1">
          <HeaderLogo />
        </Link>

        <TopMenu activeItem={activeItem} />

        <div className={Styles.rightMenuContainer}>
          <div className={Styles.user}>
            <PersonnelMenu />
          </div>
        </div>
      </div>
      <IdleSignOut />
      <SignOutModal />
    </GalaxyOneProvider>
  )
}

export default React.memo(G1Header)
