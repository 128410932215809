import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  ChevronDownIcon,
  Menu,
  MenuItem,
  Typography,
  color,
} from '@g1/components'
import clsx from 'classnames'
import Link from 'next/link'
import { AccountInfo } from 'redux/models'
import { getSelectedCounterparty } from 'redux/selectors/selectGalaxyUser'

import {
  getG1Accounts,
  isCPOAccount,
  isLendingAccount,
  isMarginTradingAccount,
  isTradingAccount,
} from 'utils/accounts'
import Styles from './TopMenu.module.scss'

const HeaderLink = ({ children, active, href, ...rest }) => {
  return href ? (
    <Link
      href={href}
      as={href}
      className={clsx(Styles.tabItem, Styles.accountsNavItem, {
        [Styles.active]: active,
      })}
      {...rest}
    >
      {children}
    </Link>
  ) : (
    <Button variant="text" disabled>
      <Typography variant="body2">{children}</Typography>
    </Button>
  )
}

export type TopMenu = {
  activeItem: string
}

const getAccountPageLink = (account: AccountInfo, index: number) =>
  isLendingAccount(account)
    ? `/g1/lending/${index}`
    : isMarginTradingAccount(account)
      ? `/g1/margin/${index}`
      : `/g1/accounts/${index}`

export const TopMenu: FC<TopMenu> = ({ activeItem }) => {
  const selectedCounterparty = useSelector(getSelectedCounterparty)
  const isActive = (...paths: string[]) => paths.indexOf(activeItem) > -1
  const [accountsMenuAnchorEl, setAccountsMenuAnchorEl] =
    useState<null | HTMLElement>(null)

  const g1Accounts = getG1Accounts(selectedCounterparty?.accountInfo)

  return (
    <div className={Styles.centerItemsContainer}>
      {selectedCounterparty.accountInfo.some(isCPOAccount) ||
      selectedCounterparty.accountInfo.some(isTradingAccount) ? (
        <HeaderLink href="/g1" active={isActive('/', '/g1')}>
          Trade
        </HeaderLink>
      ) : null}
      <div
        className={clsx(Styles.accountsTabItem, {
          [Styles.active]: isActive('/g1/accounts/[index]'),
        })}
      >
        <a
          className={clsx(Styles.accountsNavItem)}
          onClick={ev => setAccountsMenuAnchorEl(ev.currentTarget)}
        >
          <Typography variant="body2">Accounts</Typography>
          <ChevronDownIcon />
        </a>
      </div>

      <Menu
        className={Styles.dropdownMenu}
        anchorEl={accountsMenuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={() => setAccountsMenuAnchorEl(null)}
        open={Boolean(accountsMenuAnchorEl)}
        onClick={() => setAccountsMenuAnchorEl(null)}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        slotProps={{
          paper: {
            className: Styles.accountsMenu,
          },
        }}
      >
        {g1Accounts?.map((account, index) => (
          <MenuItem key={account.accountId} className={Styles.accountsMenuItem}>
            <Link href={getAccountPageLink(account, index)}>
              <Typography variant="body2">{account.accountName}</Typography>
              <Typography variant="metadata" color={color.galaxyBlack[400]}>
                {account.accountTypeName}
              </Typography>
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
