import { forwardRef } from 'react'

import { Icon, IconProps } from '@g1/components'

export const BankIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 24, ...rest }, ref) => {
    return (
      <Icon ref={ref} width={size} height={size} viewBox="0 0 20 20" {...rest}>
        <path
          d="M3 16V9H5V16H3ZM9 16V9H11V16H9ZM0 20V18H20V20H0ZM15 16V9H17V16H15ZM0 7V5L10 0L20 5V7H0ZM4.45 5H15.55L10 2.25L4.45 5Z"
          fill={rest.color || 'currentColor'}
        />
      </Icon>
    )
  },
)

BankIcon.displayName = 'BankIcon'
