import { useEffect } from 'react'
import moment from 'moment-timezone'
import { get } from 'Gatekeeper'
import { MaintenanceMessage } from './messages'
import { useMaintenanceState } from './reducer'

export type MaintenanceIntervals = {
  showBanner: boolean
  message: MaintenanceMessage
  timestamp: number | undefined
}

const maintenanceWindowDuration = get('enable_longer_maintenance_window')
  ? 60
  : 20

export function useMaintenanceIntervals(): MaintenanceIntervals {
  const [state, dispatch] = useMaintenanceState()

  useEffect(() => {
    const tick = () => {
      // Maintenance start time (in ET)
      const dailyMaintenanceStartTime = moment.tz(
        '5:00 pm',
        'h:mm a',
        'America/New_York',
      )

      const currentTime = moment()

      // Convert the ET start times to the user's local timezone
      const userTimezone = moment.tz.guess()
      const dailyStartLocal = dailyMaintenanceStartTime.tz(userTimezone)

      // Calculate the time difference between the current time and the start time of maintenance window
      const dailyDiff = dailyStartLocal.diff(currentTime, 'minutes')

      if (dailyDiff > 0 && dailyDiff <= 30) {
        dispatch({
          type: 'setBeforeMessage',
          time: dailyStartLocal.format('h:mm A'),
        })
      } else if (dailyDiff <= 0 && dailyDiff >= -maintenanceWindowDuration) {
        dispatch({
          type: 'setMaintenanceMessage',
          timestamp: dailyMaintenanceStartTime.valueOf(),
        })
      } else {
        dispatch({ type: 'clearMessage' })
      }
    }
    const timer = setInterval(tick, 1000 * 60)
    tick()
    return () => clearTimeout(timer)
  }, [dispatch])

  return state
}
