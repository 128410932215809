import { FC, ReactNode, useEffect, useState } from 'react'
import { Button, Typography } from '@g1/components'
import classNames from 'classnames'
import Image from 'next/image'
import AuthScreen from '@design-library/AuthScreen'
import { singleLogout } from 'utils/auth'
import Sentry from 'utils/sentry'
import { getOktaUrl } from './getOktaUrl'

import Styles from './SystemError.module.css'

const { Sentry: sentry } = Sentry()

export type SystemErrorProps = {
  error?: Error
  title: string
  message: ReactNode
  showReachToSupport?: boolean
}

export const SystemError: FC<SystemErrorProps> = ({
  error,
  title,
  message,
  showReachToSupport,
}) => {
  const [isSigningOut, setIsSigningOut] = useState(false)

  useEffect(() => {
    sentry.captureException(error, {
      extra: {
        page: 'SystemError',
        title,
        message,
      },
    })
  }, [error, message, title])

  return (
    <AuthScreen
      title={title}
      message={message}
      moreContent={
        showReachToSupport && (
          <Typography className={Styles.Message} variant="body2">
            If you believe you have been blocked in error, please reach out to
            Client Support at{' '}
            <a href="mailto:galaxyone@galaxy.com">galaxyone@galaxy.com</a>
          </Typography>
        )
      }
      image={
        <Image
          src="/assets/lock-icon.svg"
          alt="Lock Icon"
          width={68.5}
          height={96}
        />
      }
      actions={
        <>
          <Button variant="outlined" href={getOktaUrl()}>
            Back To Okta
          </Button>
          <Button
            className={classNames({ [Styles.SignOutButton]: isSigningOut })}
            variant="outlined"
            loading={isSigningOut}
            onClick={async () => {
              try {
                setIsSigningOut(true)
                await singleLogout()
              } finally {
                setIsSigningOut(false)
              }
            }}
          >
            Sign Me Out
          </Button>
        </>
      }
    />
  )
}
