import React from 'react'
import cx from 'classnames'
import { isG1, isProd } from 'utils/envUtils'
import { isClientPortal } from 'utils/isClientPortal'

import { getMessage } from './getMessage'
import Styles from './styles.module.scss'

const TestEnvironmentNotice: React.FC = () => {
  const isProdEnv = isProd()
  const isInternalPortal = !isClientPortal
  const isLocal = window.location.origin.includes('localhost')
  // Show banner in CP Portal QA/UAT and when running CP/Internal PROD locally
  const show = (isClientPortal && !isProdEnv) || (isLocal && isProdEnv)

  if (!show) {
    return null
  }

  const isG1App = isG1()

  return (
    <div
      className={cx({
        [Styles.environmentNotice]: true,
        [Styles.internal]: isInternalPortal,
        [Styles.get]: isClientPortal && !isG1App,
        [Styles.g1]: isG1App,
      })}
    >
      <div className={Styles.message}>{getMessage(isG1App)}</div>
    </div>
  )
}

export default React.memo(TestEnvironmentNotice)
