import { isProd, isUAT } from 'utils/envUtils'

export function getMessage(isG1: boolean): string {
  if (isG1 && isUAT()) {
    return 'GalaxyOne Demo Environment'
  }

  if (isProd()) {
    return 'This is a PRODUCTION Environment'
  }

  return 'This is a Test Environment'
}
