import { Selector } from 'react-redux'
import {
  CommonGalaxyUserData,
  CounterpartyDetails,
  G1GalaxyUserData,
  GetGalaxyUserData,
  InternalGalaxyUserData,
} from 'redux/models'
import { RootState } from 'redux/reducers/state'

export function selectGalaxyUser(
  app: 'get',
): Selector<RootState, GetGalaxyUserData>
export function selectGalaxyUser(
  app: 'internal',
): Selector<RootState, InternalGalaxyUserData>
export function selectGalaxyUser(
  app: 'g1',
): Selector<RootState, G1GalaxyUserData>
export function selectGalaxyUser(
  app: 'common',
): Selector<RootState, CommonGalaxyUserData>
export function selectGalaxyUser(app: 'get' | 'internal' | 'g1' | 'common') {
  switch (app) {
    case 'get':
      return (state: RootState) => state.GalaxyUser?.data
    case 'internal':
      return (state: RootState) => state.GalaxyUser?.data
    case 'g1':
      return (state: RootState) => state.GalaxyUser?.data
    case 'common':
      return (state: RootState) => state.GalaxyUser?.data
  }
}

export const selectCommonUser: Selector<
  RootState,
  CommonGalaxyUserData
> = state => state.GalaxyUser?.data

export const getSelectedCounterparty: Selector<
  RootState,
  CounterpartyDetails
> = state =>
  selectGalaxyUser('g1')(state)?.selectedCounterparty ??
  selectGalaxyUser('g1')(state)?.counterPartyDetails?.[0]

export const selectIsSigningOut: Selector<RootState, boolean> = state =>
  state.OktaUser?.isSigningOut
