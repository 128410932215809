import { G1GalaxyUserData, GalaxyUserRole, UserRole } from 'redux/models'

export const getUserCounterparty = (
  counterpartyId: number,
  user?: G1GalaxyUserData | null,
) => user?.counterPartyDetails.find(cpy => cpy.entityId === counterpartyId)

export const isAdminRole = ({ role }: GalaxyUserRole) => role === UserRole.Admin
export const isTraderRole = ({ role }: GalaxyUserRole) =>
  role === UserRole.Trader
export const isReadOnlyRole = ({ role }: GalaxyUserRole) =>
  role === UserRole.ReadOnly
export const isGalaxySupportRole = ({ role }: GalaxyUserRole) =>
  role === UserRole.GalaxySupport

export const isUserAdmin = (
  counterpartyId: number,
  user?: G1GalaxyUserData | null,
): boolean =>
  user
    ? getUserCounterparty(counterpartyId, user)?.roles?.some(isAdminRole)
    : false

export const isUserTrader = (
  counterpartyId: number,
  accountId: number,
  user?: G1GalaxyUserData | null,
): boolean =>
  getUserCounterparty(counterpartyId, user)
    ?.accountInfo.find(account => account.accountId === accountId)
    .roles?.some(isTraderRole) ?? false
