import { useMutation, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { UserConsentInfo } from 'models/LegalTerms'
import { selectOktaToken } from 'redux/selectors/selectOktaToken'
import errorToast from 'utils/errorToast'
import { UserConsentQueryKey, genericErrorMessage } from './const'

const updateUserConsent = async (
  oktaToken: string,
  consent: UserConsentInfo,
) => {
  const res = await fetch(`/api/legalterms/user-consent`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${oktaToken}`,
    },
    body: JSON.stringify(consent),
  })

  if (res.ok) {
    return res.json()
  } else {
    const { error } = await res.json()
    throw new Error(error)
  }
}

export const useUpdateUserConsent = () => {
  const oktaToken = useSelector(selectOktaToken)
  const queryClient = useQueryClient()

  return useMutation<UserConsentInfo, unknown, UserConsentInfo>({
    mutationFn: consent => updateUserConsent(oktaToken, consent),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [UserConsentQueryKey] }),
    onError: () => errorToast(genericErrorMessage),
  })
}
