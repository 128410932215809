import { Reducer, useReducer } from 'react'
import {
  MaintenanceMessage,
  getBeforeMaintenanceMessage,
  maintenanceMessage,
} from './messages'
import { MaintenanceIntervals } from './useMaintenanceIntervals'

type MaintenanceAction =
  | { type: 'clearMessage' }
  | { type: 'setMaintenanceMessage'; timestamp: number }
  | { type: 'setBeforeMessage'; time: string }

const initial: MaintenanceIntervals = Object.freeze({
  showBanner: false,
  message: null,
  timestamp: undefined,
})

function shouldBail(
  state: MaintenanceIntervals,
  message: MaintenanceMessage,
): boolean {
  return state.showBanner && state.message.title === message.title
}

const reduceFn: Reducer<MaintenanceIntervals, MaintenanceAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'clearMessage':
      return initial
    case 'setMaintenanceMessage': {
      const message = maintenanceMessage
      if (shouldBail(state, message)) {
        return state
      }

      return {
        showBanner: true,
        message,
        timestamp: action.timestamp,
      }
    }
    case 'setBeforeMessage': {
      const message = getBeforeMaintenanceMessage(action.time)
      if (shouldBail(state, message)) {
        return state
      }

      return {
        showBanner: true,
        message,
        timestamp: undefined,
      }
    }
  }
}

export function useMaintenanceState() {
  return useReducer(reduceFn, initial)
}
